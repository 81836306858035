<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('grade_conversion_define_title')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('grade_conversion_define_title')"
				              :isFilter="false"/>
			</template>
			<div class="row data-form">
				<div class="col-12">
					<div class="mb-4">
						<h6 class="text-uppercase mb-0">{{ $t('grade_conversion_info') }}</h6>
					</div>
				</div>
				<div class="col-12 col-md-12">
					<ValidationObserver ref="defineForm">
						<div class="row">
							<div class="col-12 col-sm-4 col-md-4 col-lg-4">
								<ValidationProvider name="fromGrade" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('grade_conversion_range_start')">
										<b-form-input
											v-model="defineForm.fromGrade"
											:state="errors[0] ? false : (valid ? true : null)"></b-form-input>
										<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-4 col-md-4 col-lg-4">
								<ValidationProvider name="toGrade" rules="" v-slot="{valid, errors}">
									<b-form-group :label="$t('grade_conversion_range_end')">
										<b-form-input
											v-model="defineForm.toGrade"
											:state="errors[0] ? false : (valid ? true : null)"></b-form-input>
										<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-4 col-md-4 col-lg-4">
								<ValidationProvider name="bauGrades" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('bau_grades')">
										<grade-selectbox
                                            value-type="grade"
                                            :grading-system-id-required="false"
											:validate-error="errors[0]"
											v-model="defineForm.bauGrades">
										</grade-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
								<b-form-group>
									<b-button variant="primary" @click="addToTable">{{ $t('add') }}</b-button>
								</b-form-group>
							</div>
						</div>
					</ValidationObserver>
				</div>
				<div class="col-12">
					<hr>
					<div class="mb-4 mt-4">
						<h6 class="text-uppercase mb-0">{{ $t('grade_conversion_define_tabel') }}</h6>
					</div>
				</div>
				<div class="col-12 col-md-12">
					<b-table striped hover :items="items" :fields="fields">
						<template #cell(delete)="data">
							<b-button type="button" variant="danger" size="sm" @click="deleteGrade(data.value)">{{ $t('delete') }}</b-button>
						</template>
					</b-table>
				</div>
				<div class="col-12 col-md-12 mt-4">
					<hr>
					<ValidationObserver ref="gradeForm">
						<div class="row">
							<div class="col-12">
								<ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('grade_conversion_name')">
										<b-form-input
											v-model="defineForm.name"
											:state="errors[0] ? false : (valid ? true : null)"></b-form-input>
										<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
								<b-form-group>
									<b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
								</b-form-group>
							</div>
						</div>
					</ValidationObserver>
				</div>
			</div>
			<CommonModal ref="verifyAddModal">
				<template v-slot:CommonModalTitle>
					<div>{{ $t('attention') }}</div>
				</template>
				<template v-slot:CommonModalContent>
					<div>
						<b-form-group>{{ $t('grade_conversion_verify_text') }}</b-form-group>
						<div class="d-flex">
							<b-button variant="primary" class="mr-3" @click="sendToTableForm">
								{{ $t('yes') }}
							</b-button>
							<b-button type="submit" variant="outline-secondary" @click="$refs.verifyAddModal.$refs.commonModal.hide()">{{ $t('no') }}</b-button>
						</div>
					</div>
				</template>
			</CommonModal>
			<CommonModal ref="verifyFormModal">
				<template v-slot:CommonModalTitle>
					<div>{{ $t('attention') }}</div>
				</template>
				<template v-slot:CommonModalContent>
					<div>
						<b-form-group>{{ $t('grade_conversion_verify_text_2') }}</b-form-group>
						<div class="d-flex">
							<b-button variant="primary" class="mr-3" @click="sendGradeForm">
								{{ $t('yes') }}
							</b-button>
							<b-button type="submit" variant="outline-secondary" @click="$refs.verifyFormModal.$refs.commonModal.hide()">{{ $t('no') }}</b-button>
						</div>
					</div>
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
    // Template
    import AppLayout from "@/layouts/AppLayout";
    import Header from "@/layouts/AppLayout/Header";
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

    // Components
    import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox";
    import CommonModal from "@/components/elements/CommonModal";

    // Services
    import GradeService from "@/services/GradeService";

    // Others
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            CommonModal,
            GradeSelectbox,
            ValidationProvider,
            ValidationObserver,
        },
        data() {
            return {
                defineForm: {
                    fromGrade: null,
                    toGrade: null,
                    bauGrades: null,
                    name: null
                },

                items: [],
                fields: null
            }
        },
        metaInfo() {
            return {
                title: this.$t("grade_conversion_define_title")
            }
        },
        methods: {
            createFields() {
                this.fields = [
                    {
                        key: 'delete',
                        label: this.$t('delete')
                    },
                    {
                        key: 'number',
                        label: this.$t('count_number')
                    },
                    {
                        key: 'fromGrade',
                        label: this.$t('grade_conversion_range_start')
                    },
                    {
                        key: 'toGrade',
                        label: this.$t('grade_conversion_range_end')
                    },
                    {
                        key: 'grade',
                        label: this.$t('bau_grade_conversion')
                    }

                ]
            },

            async addToTable() {
                const isValid = await this.$refs.defineForm.validate();
                if (isValid) {
                    this.$refs.verifyAddModal.$refs.commonModal.show()
                }
            },

            sendToTableForm() {
                let count = this.items.length + 1
                this.items.push({
                    delete: this.defineForm.bauGrades,
                    number: count,
                    fromGrade: this.defineForm.fromGrade,
                    toGrade: this.defineForm.toGrade,
                    bauGrade: this.defineForm.bauGrades,
                    grade: this.defineForm.bauGrades
                })
                this.$refs.verifyAddModal.$refs.commonModal.hide()
                this.defineForm = {
                    fromGrade: null,
                    toGrade: null,
                    bauGrades: null,
                    name: null
                }
                this.$refs.defineForm.reset();
            },

            deleteGrade(grade) {
                this.items.forEach((obj, index) => {
                    if (obj.delete == grade) {
                        this.items.splice(index, 1)
                        this.$toast.error(this.$t('grade_is_deleted_text'))
                    }
                })
            },

            async saveForm() {
                const isValid = await this.$refs.gradeForm.validate();
                if (isValid && this.items.length > 0) {
                    this.$refs.verifyFormModal.$refs.commonModal.show()
                }
                else {
                    this.$toast.error(this.$t('grade_table_is_empty'))
                }
            },

            sendGradeForm() {
                if(this.checkPermission('gradeconversion_store')){
                    let formData = {
                        name: this.defineForm.name,
                    }

                    GradeService.storeGradeConversion(formData)
                                .then(response => {
                                    const result = response.data.data

                                    this.items.forEach(obj => {
                                        let itemData = {
                                            grade_conversion_id: result.id,
                                            from_grade: obj.fromGrade,
                                            to_grade: obj.toGrade,
                                            grade: obj.grade
                                        }
                                        GradeService.storeGradeConversionGrades(itemData)
                                    })
                                    this.$toast.success(this.$t('api.' + response.data.message));
                                    this.$router.push('/grade/conversions')
                                })
                                .catch(e => {
                                    this.$refs.verifyFormModal.$refs.commonModal.hide()
                                    if (e.status == '406') {
                                        if (e.data.message) {
                                            this.$refs.gradeForm.errors.name.push(this.$t('api.' + e.data.message));
                                            this.$toast.error(this.$t('api.' + e.data.message));
                                        }
                                    }
                                    else {
                                        if (e.data.errors.name) {
                                            this.$refs.gradeForm.errors.name.push(e.data.errors.name[0]);
                                        }
                                    }
                                })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        },
        created() {
            this.createFields()
        }
    }
</script>
